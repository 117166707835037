import { TextField, Paper, Typography, Divider, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl';
import { useEffect, useState } from 'react';
import './App.css';
import getGeo from './tools/getGeo/getGeo';
import getPrice from './tools/getPrice/getPrice';

function App() {

  const [destination, setDestination] = useState('');
  const [coordinates, setCoordinates] = useState([]);
  const [ritprijs, setRitprijs] = useState('');
  const [afstand, setAfstand] = useState('');
  const [duur, setDuur] = useState('');
  const [error, setError] = useState('');
  const [adres, setAdres] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(coordinates.lat > 1)
    getPrice(coordinates.lat, coordinates.lng, setRitprijs, setAfstand, setDuur, setLoading);
  }, [coordinates])

  function handleChange(e) {
    setDestination(e.target.value);
  }

  function handleReset(e){
    e.preventDefault();
    setDestination('');
    setRitprijs('');
  }

  function handelSubmit(e) {
    e.preventDefault();
    setLoading(true);
    if(destination){
      getGeo(destination, setCoordinates, setAdres);
    }else{
      setError('Vul een geldige bedrijfsnaam in.')
      setLoading(false);
    }
  }

  return (
    <div className="App">
      <header className="App-header">
        <Paper elevation={3} style={{padding: '50px'}}>
          {!ritprijs ?
            <>
              <Typography variant="h4" gutterBottom component="div">Ritprijs Calculator</Typography>
              <Box component="form"> 
                  {error ? <Typography style={{color: 'red'}} variant="overline" gutterBottom component="div"> {error} </Typography> : ''}
                  <FormControl fullWidth sx={{ m: 1 }}>
                  <TextField
                    required
                    id="filled-required"
                    variant="filled"
                    label="Bedrijfsnaam, Stad"
                    value={destination} 
                    onChange={handleChange}
                    helperText="Naar welke van onze klanten wil je toe?"
                  />
                  </FormControl>
                  <LoadingButton type="submit" variant="contained" loading={loading} onClick={handelSubmit}>Berekenen</LoadingButton>
              </Box>
            </>
          :
            <>
              <Typography variant="h4" component="div">Je wilt naar:</Typography>
              <Typography variant="h6" component="div">{destination}</Typography>
              <Typography variant="overline" gutterBottom component="div">{adres}</Typography>
              
              <Divider style={{marginBottom: 8}} />

              <Typography variant="h6" gutterBottom component="div">
                De rit is {afstand} kilometer en duurt ongeveer {duur} minuten.
              </Typography>  
              <Typography variant="h6" gutterBottom component="div">
                Deze rit kost ongeveer: € {ritprijs}
              </Typography>  
                
              
              <Typography style={{color: 'darkGrey'}} variant="overline" component="div">
                Deze berekening is gemaakt op basis van een enkele reis, voor de heen en terugreis betaal je <strong>€ {Number(ritprijs * 2).toFixed(2)}</strong>.
              </Typography>
              <Typography style={{color: 'darkGrey', marginTop: 5, marginBottom: 10}} variant="overline" component="div">
              Voor dit bedrag kan je <strong>{Math.round(Number(ritprijs * 2).toFixed(2)/6.44)} paketten</strong> versturen.
              </Typography>
              

              <Button variant="outlined" onClick={handleReset} >Nieuwe berekening</Button>
            </>
        }
        </Paper>      
      </header>
    </div>
  );
}

export default App;
