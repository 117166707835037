import axios from "axios";

function getGeo(address, setCoordinates, setAdres) {

    var options = {
    method: 'GET',
    url: 'https://trueway-geocoding.p.rapidapi.com/Geocode',
    params: {address: address, language: 'nl', country: 'NL'},
    headers: {
        'x-rapidapi-host': 'trueway-geocoding.p.rapidapi.com',
        'x-rapidapi-key': process.env.REACT_APP_RAPID_API_KEY
    }
    };

    axios.request(options).then(function (response) {
        setCoordinates(response.data?.results[0]?.location);
        setAdres(response.data?.results[0].address)
    }).catch(function (error) {
        console.error(error);
    });
    
}

export default getGeo
