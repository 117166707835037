import axios from 'axios';
import React from 'react'

function getPrice(lat, lng, setRitprijs, setAfstand, setDuur, setLoading) {

    var options = {
        method: 'GET',
        url: 'https://trueway-matrix.p.rapidapi.com/CalculateDrivingMatrix',
        params: {
          origins: '52.168521881103516,5.430454254150391',
          avoid_ferries: 'true',
          avoid_tolls: 'true',
          avoid_highways: 'false',
          destinations: lat +','+lng,
          start_time: 'now'
        },
        headers: {
          'x-rapidapi-host': 'trueway-matrix.p.rapidapi.com',
          'x-rapidapi-key': process.env.REACT_APP_RAPID_API_KEY
        }
      };
      
      axios.request(options).then(function (response) {
        
        const data = response.data;
        const afstand = Number(data?.distances[0] / 1000).toFixed(1);
        const duur = Math.round(Number(data?.durations[0] / 60));

        const prijsAfstand = Number(afstand * 0.27);
        const prijsDuur = Number(duur * 0.42);

        setAfstand(afstand);
        setDuur(duur);
        setRitprijs(Number(prijsAfstand + prijsDuur).toFixed(2));
        setLoading(false);

      }).catch(function (error) {
          console.error(error);
      });

    return (
        <div>
            
        </div>
    )
}

export default getPrice
